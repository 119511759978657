/*
 * @Author: Alan
 * @Date: 2023-06-14 15:11:45
 * @LastEditors: Alan
 * @LastEditTime: 2023-07-28 10:55:23
 * @Description: 域名管理
 * @FilePath: /F1-M2-WEB-Code/actions/Host.config.js
 */
// 初始化 Config 对象
let Config = {};

// 定义 SL_LIVE_CMS_TOKEN、SL_CMS_URL、LIVE_CMS_URL 和 STAGE_CMS_URL 常量
export const SL_LIVE_CMS_TOKEN = "71b512d06e0ada5e23e7a0f287908ac1";
export const STAGE_CMS_URL = "https://cache.p5stag.fun88.biz"; //https://cmsapistag.fun88.biz
export const SL_CMS_URL = "https://cmsapisl.fun88.biz"; //"https://cmsapisl.fun88.biz"、"https://cmsapisl2.fun88.biz"
export const LIVE_CMS_URL = "https://cache.huya66.cc";
export const childrenStags = Array.from(
    { length: 16 },
    (_, i) => `p5stag${i + 1}.fun88.biz`
);
// 导入 Domainparse 函数（从 "$ACTIONS/util" 模块中）
import { Domainparse } from "$ACTIONS/util";

// 检查是否在浏览器环境中运行
if (typeof global.location !== "undefined") {
    // 获取当前主机名，并检查其是否与任何测试 URL 匹配
    const LocalHost = global.location.host;
    const LOCALHOST = global.location.hostname;
	const isLocalEnv = LOCALHOST === "localhost"; //是否在地端環境
    
    //STG测试环境域名
    const StagingApi = Boolean(
        [
            "p5stag.fun88.biz",
            ...childrenStags,
            "localhost:8282",
            "127.0.0.1:8080",
        ].find((v) => global.location.href.includes(v))
    );
    //灰度环境测试域名
    const SlApi = Boolean(
        [
            "p5sl.fun88.biz",
            "p5sl1.fun88.biz",
            "p5sl2.fun88.biz",
            "p5sl3.fun88.biz",
            "p5sl4.fun88.biz",
            "p5sl5.fun88.biz",
            "p5sl6.fun88.biz",
            "p5sl7.fun88.biz",
            "p5sl8.fun88.biz",
            "localhost:6868",
        ].find((v) => global.location.href.includes(v))
    );
    // 如果在测试 URL 上，则使用测试环境设置
    if (StagingApi) {
        Config = {
            HostApi: "https://gateway-idcstgf1p5th02.gamealiyun.com", //"https://gateway-idcstgf1p5th.gamealiyun.com",
            BFFSCApi: "https://febff-api-staging-m2-instance02.fun88.biz", //"https://febff-api-staging-m2-instance05.fun88.biz"
            LocalHost: "https://p5stag.fun88.biz/",
            IMAccessCode: "cbe2c39c75137de4",
            seasonId: 24,
            StrapiCmsUrl: isLocalEnv ? STAGE_CMS_URL : `https://cache.${LocalHost}`,
            isLocalEnv: isLocalEnv,
            isLIVE: false,
            isOnlyLIVE: false,
            isGameLive: false, //添加这个参数是为了区分线上和灰度 （线上游戏列表 只要 isLive = true 的游戏,灰度和测试环境不处理）
        };
    }
    // 如果在 SL Live URL 上，则使用灰度环境设置
    else if (SlApi) {
        Config = {
            HostApi: "https://gateway-idcslf5th.gamealiyun.com", //"https://gateway-idcslf5th.gamealiyun.com",
            BFFSCApi: "https://gatewaythsl-scf1.fun88.biz",
            LocalHost: "https://p5sl.fun88.biz",
            IMAccessCode: "85a983ec2611cc67",
            seasonId: 24,
            StrapiCmsUrl: isLocalEnv ? SL_CMS_URL : `https://cache.${LocalHost}`,
            isLocalEnv: isLocalEnv,
            isLIVE: true,
            isOnlyLIVE: false,
            isGameLive: false,
        };
    }
    // 否则，使用线上环境设置
    else {
        const parsed = Domainparse(window.location.host);
        const LiveHostApi = `https://gateway-idcf5th.${
            parsed.sld || "gamealiyun"
        }.${parsed.tld}`;
        Config = {
            HostApi: LiveHostApi,
            BFFSCApi: `https://gatewayth-scf1.${parsed.sld}.${parsed.tld}`,
            IMAccessCode: "85a983ec2611cc67",
            seasonId: 24,
            LocalHost: "https://" + LocalHost + "/",
            StrapiCmsUrl: `https://cache.${parsed.domain}`,
            isLocalEnv: isLocalEnv,
            isLIVE: true,
            isOnlyLIVE: true,
            isGameLive: true,
        };
    }
}

// 将 Config 导出为默认值
export default { Config };
