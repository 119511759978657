import { ApiPort } from "$ACTIONS/TLCAPI";
import { get } from "$ACTIONS/TlcRequest";
import { commonDataActions } from "$STORE/commonDataSlice";

/**
 * 從CMS取得Footer images
 * @returns
 */
export const getCMSFooter = () => {
    return async (dispatch, getState) => {
        try {
            const res = await get(ApiPort.GETCmsFooterImages);

            return dispatch(commonDataActions.setCmsFooterImages(res?.data));
        } catch (err) {
            console.error("getCMSFooter error:", err);
        }
    };
};
