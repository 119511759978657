import { isAVIFSupported, isWEBPSupported } from "$ACTIONS/helper";

let supportFormats = null;

const makeFormatSupportValue = (avif, webp) => ({ avif, webp });

function prioritizeImageFormats(
    obj,
    supportFormats = makeFormatSupportValue(false, false)
) {
    if (Array.isArray(obj)) {
        return obj.map((item) => prioritizeImageFormats(item, supportFormats));
    } else if (obj && typeof obj === "object") {
        const keys = Object.keys(obj);
        const priorityExt = ["avif", "webp"];

        for (let key of keys) {
            const camelCaseKeys = [key + "Avif", key + "Webp"];
            const snakeCaseKeys = [key + "_avif", key + "_webp"];
            const isCamelCase = camelCaseKeys.every((k) => keys.includes(k));
            const isSnakeCase = snakeCaseKeys.every((k) => keys.includes(k));

            let formatKeys, currentExt;
            if (isCamelCase) {
                formatKeys = camelCaseKeys;
            } else if (isSnakeCase) {
                formatKeys = snakeCaseKeys;
            } else {
                continue;
            }

            currentExt =
                typeof obj[key] === "string"
                    ? obj[key].split(".").pop()?.toLowerCase()
                    : "";

            for (let i = 0; i < priorityExt.length; i++) {
                const ext = priorityExt[i];

                /**
                 * According to API doc, if original image is avif/webp, then _avif _webp fields WILL BE falsy value.
                 * When this case occurs, we need to take original image as output value.
                 */
                if (ext === currentExt && obj[key]) {
                    obj[formatKeys[i]] = obj[key];
                }

                let isSupport = supportFormats[ext];
                // Skip AVIF for GIF images, since API server does not convert to animated-avif yet
                if (isSupport && currentExt === 'gif' && ext === 'avif') {
                    isSupport = false;
                }

                // Forward Compatibility: still take o[k] as final output
                if (isSupport && obj[formatKeys[i]]) {
                    obj[key] = obj[formatKeys[i]];
                    break;
                }
            }

            // To avoid API value is null
            if (!obj[key]) {
                obj[key] = location.origin + '/th/img/logo.svg';
            }
        }

        for (let key of Object.keys(obj)) {
            obj[key] = prioritizeImageFormats(obj[key], supportFormats);
        }
        return obj;
    }
    return obj;
}

export async function processImageFormats(response) {
    // 初始化图片格式支持信息（只在首次调用时执行）
    if (!supportFormats) {
        await setSupportFormats();
    }

    // 图片格式优先级调整
    return prioritizeImageFormats(response, supportFormats);
}

// 統一設定支援格式
export async function setSupportFormats() {
    const [avif, webp] = await Promise.all([
        isAVIFSupported(),
        isWEBPSupported(),
    ]);
    supportFormats = makeFormatSupportValue(avif, webp);
}

// 取得支援格式的結果
export function getSupportFormats() {
    if (!supportFormats) {
        console.log("Support formats not yet initialized. Returning defaults.");
        return makeFormatSupportValue(false, false); // 預設回退值
    }
    return supportFormats;
}
