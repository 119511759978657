/* 一级菜单 isDown 属性是索引到二级菜单的 ID */
export const menuBarData = [
    {
        key: "Sportsbook",
        expression: "体育",
        isDown: 0,
    },
    {
        key: "Esports",
        expression: "电竞",
        isDown: 1,
    },
    {
        key: "InstantGames",
        expression: "小游戏",
        isDown: 2,
    },
    {
        key: "Casino",
        expression: "真人",
        isDown: 3,
    },
    {
        key: "P2P",
        expression: "棋牌",
        isDown: 4,
    },
    {
        key: "Slot",
        expression: "电子",
        isDown: 5,
    },
    {
        key: "Lottery",
        expression: "彩票",
        isDown: 6,
    },
];
export const menuRightBarData = [
    // {
    // 	key: 'vip',
    // 	expression: 'VIP'
    // },
    {
        key: "Promotions",
        expression: "โปรโมชั่น", //优惠
    },
    {
        key: "Rewards",
        expression: "รีวอร์ด", //天王俱乐部
    },
    {
        key: "More",
        expression: "เพิ่มเติม", //更多
        isDown: 7,
    },
    {
        key: "Affiliate",
        expression: "พันธมิตร", //联盟
    },
];
/* 二级菜单  0: 体育 1: 电竞 2: 真人 3: 棋牌 4: 电子 7: 彩票 8: 更多 */
export const hoverSecondary = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [
        /* 暫時註解，待 phase3 開發頁面
            {
                title: "ทัวร์นาเมนต์",
                providerImageUrl: "/th/img/home/header/Others_Tournament.png",
                provider: "/tournament",
                gameId: "",
                type: "static",
            },
        */
        {
            title: "แนะนำเพื่อน", //推荐好友
            providerImageUrl: "/th/img/home/header/Others_ReferAFriend.png",
            provider: "/referrer-activity",
            gameId: "76",
            type: "static",
        },
        {
            title: "VIP คลับ",
            providerImageUrl: "/th/img/home/header/Others_VIP.png",
            provider: "/diamond-club",
            gameId: "",
            type: "static",
            kind: "hot",
        },
        {
            title: "คำถามที่พบบ่อย", //幫助中心
            providerImageUrl: "/th/img/home/header/Others_FAQ.png",
            provider: "/faq/abount-Fun88",
            gameId: "",
            type: "static",
        },
        {
            title: "ผู้สนับสนุนหลัก", //赞助伙伴
            providerImageUrl: "/th/img/home/header/Others_SponsorshipPage.png",
            provider: "/sponsorship",
            gameId: "",
            type: "static",
            kind: "hot",
        },
        {
            title: "ดาวน์โหลด", //下载中心
            providerImageUrl: "/th/img/home/header/Others_Download.png",
            provider: "/ดาวน์โหลด-fun88",
            gameId: "76",
            type: "static",
        },
        // {
        // 	title: '客户端下载',
        // 	providerImageUrl: '/cn/img/home/header/img-kehuduan.png',
        // 	provider: '/Download/vendor',
        // 	gameId: '76',
        // 	type: 'static'
        // },
        {
            title: "ตั้งค่าส่วนตัว​", //自我限制
            providerImageUrl:
                "/th/img/home/header/Others_BehavioralRestrictions.png",
            provider: "/me/self-exclusion",
            gameId: "76",
            type: "static",
        },
    ],
];
export const hoverSecondaryDetail = [
    {
        type: "Sportsbook",
        name: "กีฬา", //体育投注
        english: "SPORT BETTING",
        promotion: "โบนัสแรกเข้ากีฬา", //体育欢迎奖金
        promotionVal: "150",
        promotionValSuffix: "%",
        gameContent:
            "提供最及时的体育资讯、最实用的赛事分析技巧以及最靠谱的体育平台！",
        gameAddress: "/กีฬา", //Sportsbook
    },
    {
        type: "ESports",
        name: "อีสปอร์ต", //电子竞技
        english: "eSPORT BETTING",
        promotion: "คืนเงินรายวันอีสปอร์ต", //每日电竞返现
        promotionVal: "0.5",
        promotionValSuffix: "%",
        gameContent: "专业的电竞平台，为电竞玩家们带来各种多样化的游戏体验！",
        gameAddress: "/อีสปอร์ต", //ESports
    },
    {
        type: "InstantGames",
        name: "เกมความเร็ว", //小游戏
        english: "SP GAMING",
        promotion: "โบนัสแรกเข้า", //欢迎奖金
        promotionVal: "100",
        promotionValSuffix: "%",
        gameContent: "专业的电竞平台，为电竞玩家们带来各种多样化的游戏体验！",
        gameAddress: "/เกมความเร็ว", //InstantGames
    },
    {
        type: "LiveCasino",
        name: "คาสิโน", //真人娱乐
        english: "LIVE CASINO",
        promotion: "คืนเงินรายวันคาสิโน", //每日现金返还
        promotionVal: "0.8",
        promotionValSuffix: "%",
        gameContent: "乐天堂线上真人娱乐场，美女主播、性感荷官现场互动！",
        gameAddress: "/คาสิโน", //LiveCasino
    },
    {
        type: "Slot",
        name: "สล็อต/ยิงปลา", //电子游戏
        english: "SLOT GAMING",
        promotion: "โบนัสแรกเข้าสล็อต", //老虎机首次入场奖金
        promotionVal: "120",
        promotionValSuffix: "%",
        gameContent:
            "最火热的电子游戏平台，提供超多款电子游戏类型，丰厚奖金池回馈！",
        gameAddress: "/สล็อต", //Slot
    },
    {
        type: "P2P",
        name: "เกม 3 มิติ", //棋牌游戏
        english: "P2P GAMING",
        promotion: "โบนัสแรกเข้า", //欢迎奖金
        promotionVal: "100",
        promotionValSuffix: "%",
        gameContent: "玩家互动，真实对战，拥有多款火爆热门的棋牌游戏！",
        gameAddress: "/เกม-3-มิติ", //P2P
    },
    {
        type: "KenoLottery",
        name: "หวย", //彩票游戏
        english: "LOTTERY",
        promotion: "จ่ายสูงสุด", //最高支出
        promotionVal: "901",
        promotionValSuffix: "บาท",
        gameContent: "包含香港六合彩，急速时时彩，北京赛车彩等！",
        gameAddress: "/หวย", //Lottery
    },
    {
        type: "More",
        name: "เพิ่มเติม", //更多精彩
        english: "MORE FEATURES",
        promotion: "",
        promotionVal: "",
        promotionValSuffix: "",
        gameContent: "",
        qrcodeImg: "", // 二维码图片地址
        qrcodeAddress: "www.tlc880109.com",
    },
];

export const menuListInt = [
    {
        gameCatId: "123",
        gameCatCode: "Sportsbook",
        gameCatPageName: "体育",
        gameCatDesc:
            "提供最即时的体育资讯、最实用的赛事分析技巧以及最靠谱的体育平台。",
        gameCatDefaultImageUrl:
            "https://f1-api-stage-web.fubnb.com/sites/default/files/2022-04/Banner-2_630x314_1.jpg",
        gameCatIconImageUrl:
            "https://f1-api-stage-web.fubnb.com/sites/default/files/2019-08/icons_sport_selected.png",
        gameCatClass: ["天天享超高返水", "1%"],
        gameCatTitle: "体育竞技",
        gameCatSubtitle: "SPORTS BETTING",
        gameCatPromotionId: null,
    },
    {
        gameCatId: "41",
        gameCatCode: "Esports",
        gameCatPageName: "电竞",
        gameCatDesc: "专业的电竞平台，为电竞玩家们带来各种多样化的游戏体验。",
        gameCatDefaultImageUrl:
            "https://f1-api-stage-web.fubnb.com/sites/default/files/2022-04/E-Sport_ProductBanner.jpg",
        gameCatIconImageUrl:
            "https://f1-api-stage-web.fubnb.com/sites/default/files/2019-08/icons_esport_selected.png",
        gameCatClass: ["天天享超高返水", "1%"],
        gameCatTitle: "电子竞技",
        gameCatSubtitle: "eSPORT BETTING",
        gameCatPromotionId: null,
    },
    {
        gameCatId: "431",
        gameCatCode: "InstantGames",
        gameCatPageName: "小游戏",
        gameCatDesc: "",
        gameCatDefaultImageUrl:
            "https://f1-api-stage-web.fubnb.com/sites/default/files/2022-10/noimage-760x460.png",
        gameCatIconImageUrl:
            "https://f1-api-stage-web.fubnb.com/sites/default/files/2022-10/noimage-760x460_0.png",
        gameCatClass: [""],
        gameCatTitle: null,
        gameCatSubtitle: null,
        gameCatPromotionId: null,
    },
    {
        gameCatId: "124",
        gameCatCode: "Casino",
        gameCatPageName: "真人",
        gameCatDesc: "乐天堂线上真人娱乐场，美女主播、性感荷官现场互动。",
        gameCatDefaultImageUrl:
            "https://f1-api-stage-web.fubnb.com/sites/default/files/2022-04/Banner-1_630x314.jpg",
        gameCatIconImageUrl:
            "https://f1-api-stage-web.fubnb.com/sites/default/files/2019-08/icons_casino_selected.png",
        gameCatClass: ["天天享超高返水", "0.9%"],
        gameCatTitle: "真人娱乐",
        gameCatSubtitle: "LIVE CASINO",
        gameCatPromotionId: null,
    },
    {
        gameCatId: "42",
        gameCatCode: "P2P",
        gameCatPageName: "棋牌",
        gameCatDesc: "玩家互动，真实对战，拥有多款火爆热门的棋牌游戏。",
        gameCatDefaultImageUrl:
            "https://f1-api-stage-web.fubnb.com/sites/default/files/2022-04/E-Sport_TF.jpg",
        gameCatIconImageUrl:
            "https://f1-api-stage-web.fubnb.com/sites/default/files/2019-08/icons_ky_selected.png",
        gameCatClass: ["天天享超高返水", "1.2%"],
        gameCatTitle: "棋牌游戏",
        gameCatSubtitle: "FUN88 P2P GAME",
        gameCatPromotionId: null,
    },
    {
        gameCatId: "43",
        gameCatCode: "Slot",
        gameCatPageName: "电子",
        gameCatDesc:
            "最火热的电子游戏平台，提供超多款电子游戏类型，丰厚奖金池回馈",
        gameCatDefaultImageUrl:
            "https://f1-api-stage-web.fubnb.com/sites/default/files/2022-04/Banner-1_630x314_1.jpg",
        gameCatIconImageUrl:
            "https://f1-api-stage-web.fubnb.com/sites/default/files/2019-08/icons_slot_selected.png",
        gameCatClass: ["天天享超高返水", "1%"],
        gameCatTitle: "电子游戏",
        gameCatSubtitle: "SLOT GAMES",
        gameCatPromotionId: null,
    },
    {
        gameCatId: "45",
        gameCatCode: "Lottery",
        gameCatPageName: "彩票",
        gameCatDesc: "玩法简单、赔率多元、公开公正。",
        gameCatDefaultImageUrl:
            "https://f1-api-stage-web.fubnb.com/sites/default/files/2022-04/Banner-3_630x314.jpg",
        gameCatIconImageUrl:
            "https://f1-api-stage-web.fubnb.com/sites/default/files/2019-08/icons_lottery_selected.png",
        gameCatClass: ["天天享超高返水", "0.6%"],
        gameCatTitle: "彩票游戏",
        gameCatSubtitle: "LOTTERY",
        gameCatPromotionId: null,
    },
];
