import { createSlice } from "@reduxjs/toolkit";

const commonDataSlice = createSlice({
    name: "commonData",
    initialState: {
        cmsFooterImages: null, //取得CMS footer images
    },
    reducers: {
        setCmsFooterImages(state, action) {
            state.cmsFooterImages = action.payload;
        },
    },
});

export default commonDataSlice;
export const commonDataActions = commonDataSlice.actions;
