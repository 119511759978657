import { createSlice } from "@reduxjs/toolkit";

export const DEFAULT_AFFILIATE_URL = "https://www.fun6606.com/lmr/th-th/";

const mainSiteConfigSlice = createSlice({
    name: "mainSiteConfig",
    initialState: {
        affiliateUrl: DEFAULT_AFFILIATE_URL,
        affiliateCode: "",
        isSafeHouse: false,
    },
    reducers: {
        setAffiliateUrl(state, action) {
            state.affiliateUrl = action.payload || DEFAULT_AFFILIATE_URL;
        },
        setAffiliateCode: (state, action) => {
            state.affiliateCode = action.payload || "";
        },
        setDomainInfo: (state, action) => {
            state.isSafeHouse = action.payload || false;
        },
    },
});

export default mainSiteConfigSlice;

export const mainSiteConfigActions = mainSiteConfigSlice.actions;
