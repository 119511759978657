import { mainSiteConfigActions } from "$STORE/mainSiteConfigSlice";
import { getQueryVariable, Cookie } from "$ACTIONS/helper";
import { ApiPort } from "$ACTIONS/TLCAPI";
import { get } from "$ACTIONS/TlcRequest";

export const fetchAffiliateCodeThunk = () => {
    return async (dispatch, getState) => {
        let affiliateCode = null;

        // 从 URL 或 Cookie 获取 Affiliate Code
        const affCode = getQueryVariable("aff");
        if (affCode) {
            Cookie.Create("CO_affiliate", `affiliate=${affCode}`, 2);
            affiliateCode = affCode;
        } else {
            const cookieAffiliate = Cookie.GetCookieKeyValue("CO_affiliate");
            if (cookieAffiliate && cookieAffiliate !== "undefined") {
                affiliateCode = cookieAffiliate;
            }
        }

        // 如果有 affiliateCode，先设置到 Redux 中
        if (affiliateCode) {
            dispatch(mainSiteConfigActions.setAffiliateCode(affiliateCode));
        }

        // 从 API 获取 isSafeHouse、Affiliate Code
        try {
            const response = await get(ApiPort.GETDomainUrl);
            if (response && response.isSuccess && response.result) {
                const apiAffiliateCode = response.result.affiliateCode;
                const isSafeHouse = response.result.isSafeHouse || false;

                if (!affiliateCode && apiAffiliateCode) {
                    Cookie.Create("CO_affiliate", `affiliate=${apiAffiliateCode}`, 2);
                    dispatch(mainSiteConfigActions.setAffiliateCode(apiAffiliateCode));
                }

                // 更新 isSafeHouse
                dispatch(mainSiteConfigActions.setDomainInfo(isSafeHouse));
            }
        } catch (error) {
            console.error("Error fetching domain info:", error);
        }
    };
};
